import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const relatedPosts = document.querySelector('.related-posts__carousel');

  if (relatedPosts) {
    new Swiper(relatedPosts, {
      slidesPerView: 1.4,
      spaceBetween: 12,
      freeMode: true,

      breakpoints: {
        768: {
          slidesPerView: 2.4,
          spaceBetween: 24,
        },
        1280: {
          slidesPerView: 3.5,
          spaceBetween: 24,
        },
      },

      a11y: {
        firstSlideMessage: _x(
          'This is the first slide',
          'carousel first slide label',
          'vtx'
        ),
        lastSlideMessage: _x(
          'This is the last slide',
          'carousel first slide label',
          'vtx'
        ),
        paginationBulletMessage: _x(
          'Go to slide {{index}}',
          'carousel navigation label',
          'vtx'
        ),
        prevSlideMessage: _x(
          'Previous slide',
          'carousel previous label',
          'vtx'
        ),
        nextSlideMessage: _x('Next slide', 'carousel next label', 'vtx'),
      },
    });
  }
});
